@import "../css/variables.module";

.excalidraw {
  .CollabButton.is-collaborating {
    background-color: var(--button-special-active-bg-color);

    .ToolIcon__icon svg,
    .ToolIcon__label {
      color: var(--icon-green-fill-color);
    }
  }

  .CollabButton-collaborators {
    :root[dir="ltr"] & {
      right: -5px;
    }
    :root[dir="rtl"] & {
      left: -5px;
    }
    min-width: 1em;
    min-height: 1em;
    line-height: 1;
    position: absolute;
    bottom: -5px;
    padding: 3px;
    border-radius: 50%;
    background-color: $oc-green-6;
    color: $oc-white;
    font-size: 0.6em;
    font-family: "Cascadia";
  }
}
